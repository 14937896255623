'use client';
import medallia from './utils/EncuestaMedallia';

export default function MedalliaButton({ config }) {
  const openMedallia = () => {
    medallia.onClick(window, config.idForm);
  };

  return (
    <div
      className=""
      onClick={openMedallia}
      dangerouslySetInnerHTML={{
        __html: config.stylesBotton,
      }}
    />
  );
};
