'use client'
import { handleMenuToggle } from '~/components/Headers/handlers';
import { usePageContext } from '#/renderer/usePageContext';

export default function MenuToggle() {
  const { pageProps: page } = usePageContext();
  return page.isMobile ? (
    <div className="nav--trigger">
      <a onClick={handleMenuToggle}>
        <span className="nav--trigger-menu fa-bars" />
      </a>
    </div>
  ) : null;
}
