'use client'
import { Link } from '#/renderer/router';
import { usePathname } from 'next/navigation';

export default function FooterBreadcrumb() {
  const pathname = usePathname();
  const parts = pathname.split('/');
  const name = pathname
    .replace(/(\/)/g, '')
    .replace(/[-_]/g, ' ')
    .replace(/(categories.*)/, `Categoría ${parts.slice(2).join('/')}`)
    .replace(/(product.*)/, 'Equipos')
    .replace(/(cart.*)/, 'Carrito')
    .replace('terms', 'Términos y condiciones')
    .replace('search', 'Búsqueda: ')
    .replace('renewals', 'Renovaciones')
    .replace(/(account)/, 'Mi Cuenta')
    .replace(/(.*orders.*)/, 'Mis Órdenes')
    .replace(/(.*address.*)/, 'Mis Direcciones')
    .replace(/(.*profile.*)/, 'Mi Perfil');
  return (
    <div className="footer--breadcrumb">
      {/* <nav>
        <Link to="/">Tienda</Link>
        {pathname === '/' ? null : (
          <a style={{ textTransform: 'capitalize', cursor: 'default' }}>
            {name}
          </a>
        )}
      </nav> */}
    </div>
  );
}
