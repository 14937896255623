'use client';
import FooterImage from './FooterImage';
import { closeMenus } from '~/components/Footer/handlers';
import { usePathname } from 'next/navigation';

export default function Footer({ children, home }) {
  const pathname = usePathname();
  return (
    <footer className="footer" role="presentation" onClick={closeMenus}>
      {pathname.match(/\/cart/) ? null : <FooterImage data={home} />}
      {children}
    </footer>
  );
}
