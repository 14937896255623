'use client'
import { Link } from "#/renderer/router";
import { usePathname } from "next/navigation";

export default function Volver() {
    const pathname = usePathname()
    return (/^\/categories\//.test(pathname) && (
        <Link to="/" className="nav--home">
            Volver a Tienda
        </Link>
    ))
}
