'use client'
import { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

function CartQuantity(props) {
  useEffect(() => {
    props.orderStore.getCart(undefined, false);
  }, [props.orderStore]);

  let cartInfo = props.orderStore.getCartInfo?.data;
  let total = 0;
  if (cartInfo?.data?.items?.length >= 1) {
    total = 1;
  }

  return <span className="nav--qty">{total}</span>;
}

export default inject('orderStore')(observer(CartQuantity));
