//import blukai, removido
import {
  showLoginModal,
  showRegisterModal,
} from '~/components/Headers/handlers';

const LoginBtn = () => (
  <div className="ui--login-btn ui-log-btn active ie-fix">
    <a
      className="ui--btn-login"
      onClick={() => {
        showLoginModal();
        //blukai function, removida
      }}
    >
      Iniciar Sesión
    </a>
    <a
      className="ui--btn-register"
      onClick={() => {
        showRegisterModal();
        //blukai function, removida
      }}
    >
      Regístrate
    </a>
  </div>
);

export default LoginBtn;
