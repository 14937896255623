import { useEffect } from 'react';

const CardImage = ({ image }) => {
  return (
    <div className="">
      <img
        width={100}
        height={42}
        src={`${process.env.NEXT_PUBLIC_ESPOTS}/image_footer/${image}`}
        alt={`${image}`}
        loading="lazy"
      />
    </div>
  );
};

const FooterCard = ({ cardInfo }) => {
  return (
    <div className="Footer--image-item">
      {cardInfo.title && <div className="title-footer">{cardInfo.title}</div>}

      <div className="Footer--container-img">
        {cardInfo.images.map((item, index) => {
          return <CardImage key={`${item.image}-${index}`} image={item.image} />;
        })}
      </div>

      {cardInfo.subtitle && <div className="subtitle-footer">{cardInfo.subtitle}</div>}
    </div>
  );
};

export default function FooterImage({ data }) {
  const { image_footer: footerImageState, siteseal_title: sitesealTitle } = data;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = process.env.NEXT_PUBLIC_SEAL;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="Footer--image-frist">
      <div className="comenta"></div>
      <div className="Footer--image">
        {footerImageState &&
          footerImageState.map((item, index) => <FooterCard key={`${item.title}-${index}}`} cardInfo={item} />)}
        <div className="Footer--image-item siteseal--container">
          {sitesealTitle && <div className="title-footer">{sitesealTitle}</div>}
          <div className="Footer--container-img-siteseal">
            <span className="secure-logo" id="siteseal" />
          </div>
        </div>
      </div>
    </div>
  );
}
