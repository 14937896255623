'use client';
import { bandera } from '~/helpers/svg';
import { handleCountry, handleLeave } from '~/components/Headers/handlers';

export default function NavTopCountry({ children, isMobile }) {
  return (
    <nav className="nav--top-third">
      <a className="nav--top-third-trigger" onClick={handleCountry}>
        {bandera}
        <i className='ico-dropdown'></i>
      </a>
      <ul
        className="nav--top-third-dropdown"
      >
        {children}
      </ul>
    </nav>
  );
}
