import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import $ from 'jquery';

// Helpers
import validate from '../../../helpers/validate_form';

// UI
import UI from './PassRequested.ui';

class PassRequested extends Component {
  constructor(props) {
    super(props);
    this.internalData = {
      code: '',
      password: '',
      confirmPassword: '',
    };
    // Binds
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    validate();
  }

  handleChange(e, data) {
    const keys = data.split('.');
    const resetPasswordForm = this;
    resetPasswordForm[keys[0]][keys[1]] = e.target.value;
    this.setState(resetPasswordForm);
  }

  handleData() {
    const query = {
      validation_code: this.internalData.code,
      password: this.internalData.password,
      password_confirm: this.internalData.confirmPassword,
    };

    this.props.accountStore.passRequested(query);
    if (document.location.pathname === '/update_password') {
      $('.page--password-success').addClass('active');
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const isIE = (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1);
    if (isIE) this.handleData();
    else if (!isIE && $("form[name='passRequested']").valid()) this.handleData();
  }

  render() {
    if (this.props.accountStore.passReq && this.props.accountStore.passReq.success) {
      this.props.modalType('onPassRecovered');
    }

    return (
      <UI
        internalData={this.internalData}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

PassRequested.propTypes = {
  accountStore: PropTypes.shape({
    passRequested: PropTypes.func,
    passReq: PropTypes.shape({
      success: PropTypes.bool,
    }),
  }).isRequired,
  modalType: PropTypes.func,
};

PassRequested.defaultProps = {
  modalType: () => {},
};

export default inject('accountStore')(observer(PassRequested));
