'use client';
import LoginBtn from '~/containers/Account/Login/LoginBtn';
import LogoutBtn from '~/containers/Account/Login/LogoutBtn';
import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { handleScroll } from '~/components/Headers/handlers';
import useStores from '~/store/StoreHook';

export default function SessionBtn() {
  const { accountStore } = useStores();
  const [user, setUser] = useState();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    accountStore.dataPerson(false).then((res) => {
      setUser(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    const username = user?.data;
    if (username?.email_1) {
      let medalia_email = localStorage.getItem('medallia_email');
      const medalia_user_login = localStorage.getItem('medallia_user_login');
      let medalia_name = localStorage.getItem('medallia_name');
      if (medalia_user_login === 'true') {
        medalia_email = username.email_1;
        medalia_name = `${username.first_name} ${username.last_name}`;
        localStorage.setItem('medallia_email', medalia_email);
        localStorage.setItem('medallia_name', medalia_name);
      }
    }
  }, [user]);

  return user ? (
    <LogoutBtn username={user.data?.first_name} />
  ) : (
    <LoginBtn />
  );
}
