'use client'
import { handleLeave } from '~/components/Headers/handlers';

export default function NavTopUI({ children }) {
  return (
    <div className="nav--top" onMouseLeave={handleLeave}>
      {children}
    </div>
  );
}
