'use client';
import { bandera } from '~/helpers/svg';
import { handleCountry, handleLeave, handleOptions } from '~/components/Headers/handlers';

export default function NavTopOptions({ children, isMobile }) {
  return (
    <nav className="nav--top-third options">
      <a className="nav--top-third-options-trigger" onClick={handleOptions}>
        Más de Claro
        <i className='ico-dropdown'></i>
      </a>
      <ul
        className="nav--top-third-options-dropdown"
      >
        {children}
      </ul>
    </nav>
  );
}
