const medallia = {

  neb_AddEventListener: (elem, eventType, handler) => {
    if (elem.addEventListener) {
      elem.addEventListener(eventType, handler, false);
    } else if (elem.attachEvent) {
      elem.attachEvent(`on ${eventType}`, handler);
    }
  },

  onsiteLoaded: elem => () => {
    const neb_status = elem.KAMPYLE_ONSITE_SDK.loadForm(281);
    if (neb_status === true) {
      elem.KAMPYLE_ONSITE_SDK.showForm(281);
    }
  },

  onClick: (elem, id) => {
    elem.KAMPYLE_ONSITE_SDK.showForm(id);
  },

};

export default medallia;
