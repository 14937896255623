'use client'
import { cookies, guestSession } from '~/store/Sessions/Sessions';
import { consultCart } from '~/store/Orders/Cart';
import { useEffect } from 'react';

function createSessionToken() {
  if (!cookies.get('Session-Id')) {
    guestSession().then((res) => {
      if (res && res.data && res.data.data) {
        // set cookie.
        cookies.set('Session-Id', res.data.data.session_id, {
          path: '/',
        });
      }
    });
  }
}

function validateSession(interval) {
  consultCart().then((res) => {
    if (!res.success) {
      clearInterval(interval);
      createSessionToken();
    }
  });
}

export default function Session() {
  useEffect(() => {
    createSessionToken();
    const interval = setInterval(() => {
      validateSession(interval);
    }, 600000);

    return () => clearInterval(interval);
  }, []);
}
