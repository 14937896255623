'use client'
import { handleCountry } from '~/components/Footer/handlers';

export default function FooterCountry({ children }) {
  return (
    <select id="footer-country" onChange={handleCountry}>
      {children}
    </select>
  );
}
