'use client'
import $ from 'jquery';
import { browserEdge } from '~/helpers/svg';
import { useEffect, useState } from 'react';

const IEnotification = () => {
  const [isIE, setIsIE] = useState(false);

  useEffect(() => {
    const isIE =
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      navigator.appVersion.indexOf('Trident/') > -1;
    setIsIE(isIE);
  }, []);

  const close = () => {
    $('.ui--ie-notification').fadeOut();
    $('.ui--login-btn, .ui--logout-btn').removeClass('ie-fix');
  };
  return isIE ? (
    <div className="ui--ie-notification">
      <div>
        <a
          className="link"
          href="https://www.microsoft.com/es-mx/windows/microsoft-edge"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>
            Para una óptima experiencia de compra te recomendamos usar un
            navegador actualizado{' '}
          </span>
          <i>{browserEdge}</i>
        </a>
        <a className="close" onClick={close} />
      </div>
    </div>
  ) : null;
};

export default IEnotification;
