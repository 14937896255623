import $ from 'jquery';

export function handleCountry() {
  window.location = $('#footer-country').val();
}

export function closeMenus() {
  if ($(window).width() > 760) {
    $('.nav--user').removeClass('active');
    $('.nav--links-trigger').removeClass('active');
    $('.nav--menu').slideUp();
    $('.nav--menu-login').slideUp();
  }
}

export function handleImageToggle(e) {
  $(e.currentTarget).attr('src', '/cdn/espots/media/icon-chat-desplegado.gif');
}

export function handleImageLeave(e) {
  $(e.currentTarget).attr('src', '/cdn/espots/media/icon-chat-inicio.gif');
}
