import $ from 'jquery';
import { isMobile } from 'react-device-detect';

export function handleCountry() {
  $('.nav--top-third-trigger').toggleClass('active');
  $('.nav--top-third-dropdown').toggleClass('open');
  $('.nav--top-third-options-dropdown').slideUp();
  $('.nav--top-third-options-trigger').removeClass('active');
  $('.nav--user').removeClass('active');
  $('.nav--links-trigger').removeClass('active');
  $('.nav--menu-login').slideUp();
}
export function handleOptions() {
  $('.nav--top-third-options-trigger').toggleClass('active');
  $('.nav--top-third-options-dropdown').toggleClass('open');
  $('.nav--top-third-dropdown').slideUp();
  $('.nav--top-third-trigger').removeClass('active');
  $('.nav--user').removeClass('active');
  $('.nav--links-trigger').removeClass('active');
  $('.nav--menu-login').slideUp();
}

export function handleHover() {
  $('.nav--top-second-dropdown').slideDown();
}

export function handleLeave() {
  $('.nav--top-second-dropdown').slideUp();
}

export function handleScroll() {
  const y = window.scrollY;
  if (y >= 1) {
    // $('.nav--top').slideUp(100);
    // $('.nav--top').slideUp(140);
    $('.nav').addClass('fixed');
    $('.ui-log-btn').removeClass('active');
    $('.nav--home').addClass('hide');
    if (isMobile) {
      $('.nav--search.category').addClass('hide');
      $('.labelSearchBar').removeClass('showBar');
    }
  } else {
    // $('.nav--top').slideDown(100);
    // $('.nav--top').slideDown(140);
    $('.nav').removeClass('fixed');
    $('.ui-log-btn').addClass('active');
    $('.nav--home').removeClass('hide');
    if (isMobile) {
      $('.nav--search.category').removeClass('hide');
    }
  }
  $('.nav--top-third-dropdown').slideUp();
  $('.nav--top-third-trigger').removeClass('active');
}

export function handleChange() {
  window.location = $('#nav-top-mobile-select').val();
}

// NavMain
export function handleSearchToggle() {
  $('.nav--trigger-search').toggleClass('fa-search fa-times');
  $('.nav--search').slideToggle();
  $('.nav--menu-content.active').removeClass('active');
  $('.nav--trigger-menu.fa-times').toggleClass('fa-bars fa-times');
}

export function handelToggleSubItemSidebar(title) {
  $(`.subMenu-item.item-${title}`).toggleClass('active');
}
export function handleCloseSideBar() {
  $(`.menu-body-items-list`).removeClass('hideMenu');
  $(`.subMenu`).removeClass('open');
}
export function handleToogleSideBar(tittle) {
  $(`.menu-body-items-list`).toggleClass('hideMenu');
  $(`.subMenu.subMenu-${tittle}`).toggleClass('open');
}

export function handleToogleFooter(tittle) {
  $(`.footer-links-element-list.list-${tittle}`).toggleClass('active');
  $(`.footer-links-element.element-${tittle}`).toggleClass('active');
}

export function handleCloseMenuMobile() {
  $('.nav--menu').removeClass('active');
}
export function handleMenuToggle() {
  // toggle menu
  // $('.nav--trigger-menu').toggleClass('fa-bars fa-times');
  $('.nav--menu-content').toggleClass('active');
  $('.nav--menu').toggleClass('active');
  // close search
  // $('.nav--search').slideUp();
  // $('.nav--trigger-search.fa-times').toggleClass('fa-search fa-times');
  // close submenus
  $('.nav--menu-list').slideUp();
  $('.nav--menu-login').slideUp();
  // $('.mobile-icon').addClass('fa-plus').removeClass('fa-minus');
  // re-enable scroll
  // -- $("body.overhidden").removeClass("overhidden");
}

// -- Desktop
export function handleMenuLinks() {
  $('.nav--links-trigger').remove('active');
  $('.nav--user').removeClass('active');
  $('.nav--menu-login').slideUp();
  $('.nav--menu').slideToggle();
  $('.nav--menu-list-tienda').hide();
  $('.nav--menu-list').show();
}
export function handleMenuLinksJSON(name) {
  const elements = document.querySelectorAll('.nav--links-menu--'+name);
  elements.forEach(element => {
    element.style.display = 'block';
  });
  // $('.nav--links-trigger').remove('active');
  // $('.nav--user').removeClass('active');
  // $('.nav--menu-login').slideUp();
  // $('.nav--menu').slideToggle();
  // $('.nav--menu-list-tienda').hide();
  // $('.nav--menu-list').show();
}
export function handleDisableMenuLinksJSON(name) {
  const elements = document.querySelectorAll('.nav--links-menu--'+name);
  elements.forEach(element => {
    element.style.display = 'none';
  });
  // $('.nav--links-trigger').remove('active');
  // $('.nav--user').removeClass('active');
  // $('.nav--menu-login').slideUp();
  // $('.nav--menu').slideToggle();
  // $('.nav--menu-list-tienda').hide();
  // $('.nav--menu-list').show();
}

export function handleMenuLinksTienda() {
  $('.nav--links-trigger').remove('active');
  $('.nav--user').removeClass('active');
  $('.nav--menu-login').slideUp();
  $('.nav--menu').slideToggle();
  $('.nav--menu-list-tienda').show();
  $('.nav--menu-list').hide();
}

export function handleMenuLogin() {
  $('.nav--user').toggleClass('active');
  $('.nav--links-trigger').removeClass('active');
  $('.nav--menu-login').slideToggle();
}

export function handleClicks() {
  $('.nav--user').removeClass('active');
  $('.nav--links-trigger').removeClass('active');
  $('.nav--menu-content').removeClass('active');
  // $('.nav--menu-login').slideUp();
  const navTriggerMenu = $('.nav--trigger-menu');
  if (navTriggerMenu.hasClass('fa-times')) {
    navTriggerMenu.toggleClass('fa-bars fa-times');
  }
}

// NavMenuLinks
export function handleSubmenuMobile() {
  // toggle submenu
  $('.nav--menu-list').slideToggle();
  $('.icon-list').toggleClass('fa-plus fa-minus');
  // switch from login
  $('.nav--menu-login').slideUp();
  // $('.icon-login').addClass('fa-plus').removeClass('fa-minus');
  // enable/disable scroll
  // -- $("body").toggleClass("overhidden");
}

export function handleSubmenuMobileTienda() {
  // toggle submenu
  $('.nav--menu-list-tienda').slideToggle();
  $('.icon-list-tienda').toggleClass('fa-plus fa-minus');
  // switch from login
  $('.nav--menu-login').slideUp();
  // $('.icon-login').addClass('fa-plus').removeClass('fa-minus');
  // enable/disable scroll
  // -- $("body").toggleClass("overhidden");
}

export function handleLoginMobile() {
  // // toggle login
  // $('.nav--menu-login').slideToggle();
  // $('.icon-login').toggleClass('fa-plus fa-minus');
  // // switch from list
  // $('.nav--menu-list').slideUp();
  // $('.icon-list').addClass('fa-plus').removeClass('fa-minus');
}

// NavLogin
export function handleLogin(e) {
  e.preventDefault();
  $('.nav--user').toggleClass('active');
  $('.nav--menu-login').slideUp();
}

export function showLoginModal() {
  $('.modal').toggleClass('active').show();
  $('.modal--user').toggleClass('active');
}

export function showRegisterModal() {
  showLoginModal();
  setTimeout(() => {
    document.querySelector('.trigger-register').click();
  }, 1);
}
